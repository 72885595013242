<template>
  <div v-if="!cardCookie" class="layout-container mb-48 md:mb-64">
    <div v-if="isEpiEdit" class="mb-24">
      <div
        v-epi-edit="'BackgroundColor'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Change background color
      </div>
      <div
        v-epi-edit="'Icon'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Icon
      </div>
      <div
        v-epi-edit="'Logo'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Logo
      </div>
      <div
        v-epi-edit="'AddCloseButton'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Show close button (overwrites icon)
      </div>
    </div>
    <div
      class="rounded-md p-16 relative sm:p-24"
      :class="{
        'pr-32 sm:pr-48': model.addCloseButton || model.icon,
        'md:flex md:items-center': model.logo,
        'border border-grey400': model.backgroundColor === '#FFFFFF',
      }"
      :style="`background-color: ${model.backgroundColor};`"
    >
      <div
        v-if="model.addCloseButton"
        class="bg-white rounded-full h-24 w-24 border border-grey400 absolute top-16 right-16 flex items-center justify-center cursor-pointer sm:right-24 md:top-24 hover:border-black animate-all"
        @click="cardCookie = 'true'"
      >
        <XMarkIcon
          class="w-16 h-16"
        />
      </div>
      <div
        v-else-if="model.icon"
        class="h-24 w-24 absolute top-16 right-16 sm:right-24 md:top-24"
      >
        <img
          v-epi-edit="'Icon'"
          :src="model.icon.url"
          alt="Card icon"
          height="24"
          width="24"
        >
      </div>
      <img
        v-if="model.logo"
        v-epi-edit="'Logo'"
        :src="model.logo.url"
        class="max-w-112 object-contain flex-shrink-0 mb-16 md:max-h-none md:max-w-full md:w-1/4 md:pl-12 md:pr-32 md:mb-0"
        alt="card logo"
      >
      <WysiwygWrapper
        v-if="model.mainBody"
        v-epi-edit="'MainBody'"
        :style-config="{ 'wysiwyg': true }"
        :html="model.mainBody"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CardBlock } from '~/content-types';
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon';

import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
const { isEpiEdit } = storeToRefs(usePageContentStore());

const props = defineProps<{
  model: CardBlock;
}>();

const cardCookie = useCookie(props.model.contentLink.guidValue + '-info-card', {
  maxAge: 60*60*24*7,
});
</script>
